<template>
    <v-container>
        <VTopToolbar title="Profile"></VTopToolbar>

        <div v-show="loading">
            <v-skeleton-loader
                type="list-item-avatar-two-line, list-item-three-line, divider, list-item"
            ></v-skeleton-loader>
        </div>
        
        <div v-show="!loading">
            <div class="d-flex">
                <div class="flex-grow-0 mx-4">
                    <v-avatar size="85">
                        <img v-bind:src="profileImageSrc" />
                    </v-avatar>
                </div>
                
                <div class="flex-grow-1 mt-3">
                    <h3 class="grey--text text--darken-3">{{ name }}</h3>
                    <!-- <div class="grey--text text--darken-1 mt-1">{{personaName}}</div>  // don't show - confusing for investigator (= fee earner)  -->
                </div>
            </div>

            <v-list dense :disabled=true>
                <v-list-item-group v-if="availability">
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon :color="availability.colour">{{availability.icon}}</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title :class="availability.colour + '--text text--darken-2 font-weight-regular'">
                                {{availability.name}}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                        v-for="(item, i) in contactDetails"
                        :key="i"
                        >
                        <v-list-item-icon>
                            <v-icon color="grey">{{item.icon}}</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title class="font-weight-regular grey--text text--darken-2">
                                {{item.value}}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>

        </div>
    </v-container>
</template>

<script>
import { SharedoProfile } from "@sharedo/mobile-core";
import profileAgent from "./profileAgent.js";

const FALLBACK_CONTACT_ICON = "mdi-access-point";

export default {
    data() {
        return {
            loading: true,
            name: null,
            profileImageDefault: null,
            profileImageBase64: null,
            personaName: null,
            availability: {},
            contactDetails: [],

            // Hardcode since unlikely to change
            availabilityOptions: {
                "available": { name: "Available", icon: "mdi-check", colour: "success" },
                "out-of-office": { name: "Out of Office", icon: "mdi-clock-outline", colour: "warning" },
                "not-available": { name: "Not Available", icon: "mdi-cancel", colour: "error" },
            },
            contactTypes: {
                "email": { icon: "mdi-email-outline" },
                "mobile": { icon: "mdi-phone-outline" },
                "direct-line": { icon: "mdi-phone-outline" },
                // ... the rest are not "on form"
            },
        };
    },
    computed: {
        profileImageSrc () {
            return this.profileImageBase64 ?? this.profileImageDefault;
        },
    },
    mounted() {
        this.name = SharedoProfile.profile.name;

        this.profileImageDefault = profileAgent.defaultProfileUrl;

        profileAgent.getMyProfile().then(profile => {
            this.loading = false;

            this.personaName = profile.personaName;

            this.availability = this.availabilityOptions[profile.availabilitySystemName] || {};

            this.contactDetails = profile.aspectData.contactDetails
                .map(cd => {
                    let icon = FALLBACK_CONTACT_ICON;

                    if (this.contactTypes[cd.contactTypeSystemName]) {
                        icon = this.contactTypes[cd.contactTypeSystemName].icon;
                    }

                    return {
                        value: cd.contactValue,
                        icon,
                    }
                })
                .filter(cd => cd.icon);

        }).catch(console.error);

        profileAgent.getProfileImage().then(image => {
            this.profileImageBase64 = image ? `data:${image.mimeType};base64,${image.contents}` : null;
        }).catch(console.error);
    },
    methods: {
        showActionSheet: function () {
            this.$coreUi.actionSheet({
                items: [
                    { text: "Notification settings", color: "primary", icon: "mdi-bell-outline",
                        handler: () => this.$router.push({ name: "notification-settings" }) },
                ]
            });
        },
    },
};
</script>

<style scoped>
.item-subtitle {
    font-size: 11px;
}
</style>
