import { SharedoFetch, SharedoProfile } from '@sharedo/mobile-core'
import settings from "../../app/settings.js";

function getMyProfile()
{
    // TODO public API
    return SharedoFetch.get(`/api/aspects/ods/user/${SharedoProfile.profile.userId}`);
}

function getProfileImage(userId)
{
    // TODO public API
    return SharedoFetch.get(`/api/user/${userId || SharedoProfile.profile.userId}/profileImage/asBase64`);
}

const defaultProfileUrl = `${settings.api}/theme/images/default-profile.jpg`;

export default 
{
    defaultProfileUrl,
    getMyProfile,
    getProfileImage,
};
